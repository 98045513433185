/* Portfolio.css */
.image-wrapper {
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .image-wrapper {
      margin: 0 1vh; /* Adjust margin for medium screens */
  }
}

@media (max-width: 768px) {
  .image-wrapper {
      margin: 0 1vh; /* Adjust margin for small screens */
  }
}

@media (max-width: 576px) {
  .image-wrapper {
      margin: 0 1vh; /* Adjust margin for extra small screens */
  }
}
