/* Combined styles for Services related components */

/* Keyframes for animations */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* General Styles for service components */
.container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.row {
    text-align: center;
}

.col-md-4 {
    margin-bottom: 2rem;
    animation: slideInFromLeft 0.8s ease-out forwards;
}

.col-md-4 h4 {
    margin-top: 1rem;
}

.col-md-4 p {
    font-size: 1rem;
    color: #666;
}

.fa-icon {
    margin-bottom: 1rem;
}

.custom-icon-color {
    color: #284053;
}

/* Circular images specific styles */
.rounded-circle {
    object-fit: cover;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
    animation: slideUp 0.5s ease-out forwards;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rounded-circle:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .col-md-4 {
        margin-bottom: 1.5rem;
    }
}

/* Service Options styles */
.service-item {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.service-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item h4 {
    color: #284053;
}

.service-item p {
    color: #666;
}
