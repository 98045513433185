/* AboutUs.css */
/* Text container animation */
.animate-text-about {
    animation: slideInFromLeft 1s ease-out forwards;
    text-align: justify;
  }
  
  /* Carousel container animation */
  .animate-carousel-about  {
    animation: slideInFromRight 1s ease-out forwards;
  }
  
  h2 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .carousel-item {
    height: 400px; /* Fixed height for carousel items */
  }
  
  .carousel-item img {
    width: 100%; /* Full width of the carousel */
    height: 100%; /* Full height of the carousel */
    object-fit: cover; /* Maintain aspect ratio while covering available space */
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .carousel-indicators {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .carousel-item-about {
    overflow: hidden !important
  }
  
  .carousel:hover .carousel-control-prev,
  .carousel:hover .carousel-control-next,
  .carousel:hover .carousel-indicators {
    visibility: visible;
    opacity: 1;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* CustomBanner.css */
  .bg-primary {
    background-color: #284053 !important;
  }
  
  .lead {
    color: #ffffff!important;
    font-size: 1rem;
    font-weight: 300;
  }
  
  .custom-text {
    padding: 1% 2%;
  }
  
  @media (min-width: 768px) {
    .lead {
      font-size: 1.25rem;
    }
    .custom-text {
      padding: 1.5% 3%;
    }
  }
  
  @media (min-width: 992px) {
    .lead {
      font-size: 1.5rem;
    }
  }
  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* FAQComponent.css */
  @keyframes slideUpFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Main title animation */
  .main-title {
    animation: slideUpFromBottom 0.5s ease-out forwards;
  }
  
  /* FAQ entries animation */
  .faq-entry:nth-child(even) h4 {
    animation: slideInFromRight 0.5s ease-out forwards;
  }
  
  .faq-entry:nth-child(odd) h4 {
    animation: slideInFromLeft 0.5s ease-out forwards;
  }
  
  /* Existing styles */
  .container {
    display: block!important;
    max-width: 1200px;
    margin: auto;
  }
  
  .faq-entry {
    margin-bottom: 40px;
  }
  
  hr {
    border: none;
    height: 2px;
    background-color: #CCCCCC;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  h2, h4 {
    font-weight: bold;
    color: #333;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    text-align: center;
  }
  
  h4 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
  @media (min-width: 992px) {
    .col-md-6 {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  