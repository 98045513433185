.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.header.scrolled {
  background-color: white;
}

.header .navbar .nav-link {
  color: #ffffff !important;
}

.header.scrolled .navbar .nav-link {
  color: #000000 !important;
}

.header .navbar .social-icon {
  color: #ffffff;
}

.header.scrolled .navbar .social-icon {
  color: #000000;
}

.logo {
  height: 50px;
}

.logo-blue {
  display: none;
}

.logo-white {
  display: block;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: #ffffff !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Responsive adjustments for devices with max width of 1200px */
@media (max-width: 1200px) {
  .header img {
    max-width: 80px !important;
    height: auto !important;
  }
}

/* Responsive adjustments for devices with max width of 992px */
@media (max-width: 992px) {
  .header img {
    max-width: 70px !important;
    height: auto !important;
  }
}

/* Responsive adjustments for devices with max width of 768px */
@media (max-width: 768px) {
  .header {
    position: static;
    background-color: #284053;
    color: #000000;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .logo-white {
    display: none;
  }

  .logo-blue {
    display: block;
  }

  .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.1);
    color: #000000 !important;
  }


  .navbar-nav .nav-link {
    margin: 0;
    padding: 10px 0;
    color: #000000 !important;
  }

  .social-icon {
    color: #000000 !important;
  }

  .navbar-collapse {
    justify-content: space-between;
  }

  .navbar-nav .dropdown-menu {
    background-color: #ffffff;
  }

  .dropdown-item {
    color: #000000 !important;
  }

  .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .header img {
    max-width: 250px !important;
    height: auto !important;
  }
}

/* Responsive adjustments for devices with max width of 576px */
@media (max-width: 576px) {
  .header img {
    max-width: 250px !important;
    height: auto !important;
  }

  .container-fluid {
    padding: 0.3vh !important;
  }
  
}
