/* Banner.css */
.banner-container {
  padding: 1vh 2vh;
  background-color: #284053;
  color: white;
  margin-top: 20px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20vh;
  padding-right: 20vh;
}

.banner-text {
  flex: 1;
}

.banner-mission {
  font-size: 1.5rem; /* Base font size */
  padding: 5px 0;
  color: white;
  font-family: "Arial", sans-serif;
  line-height: 1.2;
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.banner-button {
  margin-left: 20px;
}

.my-button-portfolio {
  background-color: white !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important;
  text-decoration: none;
}

.my-button-portfolio:hover {
  background-color: #284053 !important;
  border-color: #284053 !important;
  color: white !important;
}

@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    text-align: center;
    padding-left: 1vh !important;
    padding-right: 1vh !important;
  }

  .banner-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .banner-mission {
    font-size: 1.2rem !important;
  }

  .my-button-portfolio {
    font-size: 18px !important;
    padding: 8px 30px !important;
  }
}

@media (max-width: 480px) {
  .banner-mission {
    font-size: 1rem !important;
  }

  .my-button-portfolio {
    font-size: 16px !important;
    padding: 6px 20px !important;
  }
}

/* BackgroundWithCards.css */
.background-container {
  background-color: white; /* Updated background color */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* For positioning carousel buttons */
  overflow: hidden;
}

.text-piscinas {
  color: #284053; /* Semi-transparent background for cards */
}

.card {
  background: #f5f5f5; /* Semi-transparent background for cards */
  border: none;
  transition: transform 0.3s ease-in-out;
  color: #284053; /* Text color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-a-orcamento {
  text-decoration: none;
  color: white;
}

.card:hover {
  transform: scale(1.05); /* Slightly enlarge the card on hover */
}

.card-img-top {
  padding: 10px; /* Add padding around the image */
}

.card-title {
  color: #284053 !important; /* Ensure the text is the specified color */
  text-align: center !important; /* Center the text */
}

.card-text {
  color: #284053 !important; /* Ensure the text is the specified color */
  text-align: center !important; /* Center the text */
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: #284053;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .background-container {
    flex-direction: column;
  }

  .card {
    width: 80%;
    margin-bottom: 20px;
  }

  .carousel-button {
    font-size: 1.5rem;
  }

  .display-card {
    display: contents !important;
  }
}

@media (max-width: 576px) {
  .carousel-button {
    font-size: 1rem;
  }

  .card {
    width: 100%;
  }
}

/* Improved Button Style */
.btn-custom-home {
  background-color: #284053 !important;
  border: 2px solid white !important;
  border-radius: 20px !important;
  padding: 1vh 1vh !important;
  color: white !important;
  font-family: Arial, sans-serif;
  font-size: 15px !important; /* Bootstrap primary color */
}

.btn-custom-home:hover {
  background-color: white !important;
  border-color: #284053 !important;
  color: #284053 !important;
}

/* Service Item Styles */
.service-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 1.5rem; /* Reduced margin */
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item h4 {
  color: #284053;
}

.service-item p {
  color: #666;
}

.my-button-contact {
  background-color: white !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
}

.my-button-contact:hover {
  background-color: #284053 !important;
  border-color: #284053 !important;
  color: white !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .contact-form .col-md-4,
  .contact-form .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Carousel.css */
.custom-carousel {
  width: 100%;
  height: 100vh; /* Full viewport height */
}

.carousel-item img,
.carousel-item video {
  width: 100%;
  height: 100vh; /* Full viewport height */
  object-fit: cover;
}

.carousel-inner {
  overflow: visible !important;
}

.my-button {
  background-color: white !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
}

.my-button:hover {
  background-color: #284053 !important;
  color: white !important;
}

.caption-title {
  font-size: 2.5rem;
}

.caption-text {
  font-size: 1rem;
  padding-bottom: 4vh;
  color: #fff;
}

@media (max-width: 768px) {
  .carousel-caption {
    top: 40%;
    font-size: 0.8rem;
  }

  .caption-title {
    font-size: 1.5rem;
  }

  .caption-text {
    font-size: 0.875rem;
  }

  .my-button {
    font-size: 1rem;
    padding: 8px 30px;
  }
}

/* Carousel.css */
.custom-carousel {
  width: 100%;
  height: 100vh; /* Full viewport height */
}

.carousel-item img,
.carousel-item video {
  width: 100%;
  height: 100vh; /* Full viewport height */
  object-fit: cover;
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 0 15px;
  margin-top: 20vh;
}

.carousel-inner {
  overflow: visible !important;
}

.my-button {
  background-color: white !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
}

.my-button:hover {
  background-color: #284053 !important;
  color: white !important;
}

.caption-title {
  font-size: 3rem;
  color: #fff;
}

.caption-text {
  font-size: 1rem;
  padding-bottom: 4vh;
  color: #fff;
}

@media (max-width: 768px) {
  .caption-title {
    font-size: 1.5rem;
  }

  .caption-text {
    font-size: 0.875rem;
  }

  .my-button {
    font-size: 1rem;
    padding: 8px 30px;
  }
}

/* Carousel.css */
.custom-carousel {
  width: 100%;
  height: 100vh; /* Full viewport height */
}

.carousel-item img,
.carousel-item video {
  width: 100%;
  height: 100vh; /* Full viewport height */
  object-fit: cover;
}

.carousel-inner {
  overflow: visible !important;
}

.my-button {
  background-color: white !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
}

.my-button:hover {
  background-color: #284053 !important;
  color: white !important;
}

.caption-title {
  font-size: 2.5rem;
}

.caption-text {
  font-size: 1rem;
  padding-bottom: 4vh;
  color: #fff;
}

@media (max-width: 768px) {
  .caption-title {
    font-size: 1.5rem;
  }

  .caption-text {
    font-size: 0.875rem;
  }

  .my-button {
    font-size: 1rem;
    padding: 8px 30px;
  }
}

/* QuoteForm.css */
.quote-form-container {
  max-width: 600px;
}

.quote-form-box {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 16px;
  position: relative; /* Added for positioning the top line */
}

.top-line {
  height: 5px; /* Height of the top border line */
  background-color: #284053; /* The color you specified */
  width: 100%; /* Ensures it spans the width of the container */
  position: absolute;
  top: 0;
  left: 0;
}

.quote-form-header {
  color: #333;
  text-align: center;
}

.quote-form-header-text {
  display: flex;
  flex-wrap: wrap;
}

.quote-form-header h5 {
  color: #284053 !important;
  text-align: center;
}

.quote-form-p {
  text-align: center;
}

.quote-form-button {
  background-color: #284053; /* Or any other color you'd prefer */
  width: 100%;
  padding: 10px;
  border: none;
}

/* Services.css */
.services-container {
  width: 100%;
  text-align: center;
  margin: 4vh 0;
}

.cards-container {
  display: flex;
  justify-content: center; /* Center the cards in the container */
  flex-wrap: wrap; /* Ensures cards wrap on smaller screens */
  gap: 20px; /* Add some space between the cards */
  margin-top: 5vh;
}

.cards-container a {
  text-decoration: none;
}

.service-card:hover {
  transform: translateY(-10px); /* Move the card up when it is hovered */
}

.service-card {
  width: 100%; /* Set to full width initially */
  max-width: 250px; /* Define a maximum width for the cards */
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 2vh;
}

.card-image {
  height: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.card-overlay {
  text-align: center;
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card h4 {
  margin: 1vh 0;
  font-weight: normal; /* Use a normal font weight for a lighter appearance */
  font-size: 1.25rem; /* Smaller than h2 for subtlety */
}

.service-card button {
  background-color: #284053;
  color: white;
  border: none;
  border-radius: 2vh;
  padding: 1vh 4vh;
  font-size: 18px; /* Smaller font size for a smoother look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth all transitions */
  cursor: pointer; /* Cursor pointer to indicate button */
}

.service-card button:hover {
  background-color: white;
  color: #284053;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover for effect */
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column; /* Stack the cards vertically */
    align-items: center; /* Center the cards in the container */
  }

  .service-card {
    width: 80%; /* Make cards take up more width on smaller screens */
    margin: 10px auto; /* Center the cards with automatic margins */
  }

  .quote-form-header {
    color: #284053 !important;
    text-align: left !important;
  }
}

/* ServicesGrid.css */
.bg-primary {
  background-color: #284053 !important;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates four columns */
  gap: 20px; /* Space between grid items */
  margin: 20px;
}

.grid-item {
  background: white; /* White background for grid items */
  border-radius: 10px; /* Rounded corners for the grid items */
  padding: 20px;
  text-align: center; /* Centering content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.grid-item img {
  width: 50px; /* Fixed size for icons */
  height: auto;
  margin-bottom: 10px; /* Space below the icon */
}

.grid-item h4,
.grid-item p {
  color: #284053;
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr; /* Stacks the items into one column on small screens */
  }

  .grid-item {
    margin-bottom: 20px; /* Add some space between items */
  }
}

/* Banner.css */
.banner-container {
  padding: 1.5vh 20vh; /* Adjust padding as needed */
  background-color: #284053; /* Background color */
  color: white; /* Text color */
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-text {
  flex: 1;
}

.banner-mission {
  font-size: 1.5rem; /* Adjust font size */
  padding: 5px 0; /* Reduce padding between paragraphs */
  color: white; /* Ensure text is white */
  font-family: "Arial", sans-serif; /* Change font family */
  line-height: 1.2; /* Adjust line height for better readability */
  margin-bottom: 5px; /* Reduce margin between paragraphs */
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.banner-button {
  margin-left: 20px; /* Add space between text and button */
}

.my-button-portfolio {
  background-color: white !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Adjust font size */
}

.my-button-portfolio:hover {
  background-color: #284053 !important;
  border-color: white !important;
  color: white !important;
}

@media (max-width: 1302px) {
  .banner-container {
    padding: 1.5vh 5vh; /* Adjust padding as needed */
  }
}

@media (max-width: 768px) {
  .banner-container {
    padding: 1.5vh 10vh; /* Adjust padding as needed */
  }

  .banner-content {
    flex-direction: column;
    text-align: center;
  }

  .banner-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .banner-mission {
    font-size: 1.2rem;
  }

  .my-button-portfolio {
    font-size: 18px !important;
    padding: 8px 30px !important;
  }
}

/* ThreeDModelViewer.js */

.quote-form-container {
  max-width: 600px;
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add padding for spacing */
}

.quote-form-box {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  padding: 16px;
  position: relative; /* Added for positioning the top line */
}

.top-line {
  height: 5px; /* Height of the top border line */
  background-color: #284053; /* The color you specified */
  width: 100%; /* Ensures it spans the width of the container */
  position: absolute;
  top: 0;
  left: 0;
}

.quote-form-header {
  color: #333;
  text-align: center;
}

.quote-form-p {
  text-align: center;
}

.quote-form-button {
  background-color: #284053; /* Or any other color you'd prefer */
  width: 100%;
  padding: 10px;
  border: none;
}

/* ThreeDModelViewer.css */
.threeD-model-viewer-container {
  max-width: 600px;
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add padding for spacing */
}

.quote-form-box canvas {
  width: 100%;
  height: 500px; /* Adjust height as needed */
}

.my-button-3dModel {
  background-color: white !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Adjust font size */
  margin-bottom: 6vh;
}

.my-button-3dModel:hover {
  background-color: #284053 !important;
  border-color: #284053 !important;
  color: white !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .button-3dModel {
    text-align-last: left;
  }

  .quote-form-container,
  .threeD-model-viewer-container {
    max-width: 100%;
    padding: 10px;
  }
}
