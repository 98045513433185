/* Existing styles for Automatic Covers components */
@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.equipment-product-layout .cover-img {
  max-width: 100%;
  max-height: 200px;
  margin: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.equipment-product-layout .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.equipment-product-layout .text-section,
.text-section-white {
  animation: slideInFromTop 0.5s ease-out forwards;
}

.equipment-product-layout .text-section-white h2 {
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.equipment-product-layout .text-section-white p {
  margin-bottom: 20px;
  color: white;
}

.equipment-product-layout .icon-features .col {
  text-align: center;
  padding: 10px;
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.equipment-product-layout .cover-img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.blue-background {
  background-color: #284053;
}

@media (max-width: 1200px) {
  .equipment-product-layout {
    padding: 4% 4% !important;
  }
}

@media (max-width: 992px) {
  .equipment-product-layout {
    padding: 3% 3% !important;
  }
}

@media (max-width: 768px) {
  .equipment-product-layout .text-section,
  .equipment-product-layout .icon-features .col {
    text-align: center !important;
  }

  .equipment-product-layout {
    padding: 2% 2% !important;
  }

  .feature-item {
    width: 60% !important;
    margin: 0 20% 2rem 20% !important; /* 20% margin on each side */
    padding: 10px !important; /* Reduce padding */
  }

  .feature-item h4,
  .feature-item p {
    font-size: 0.875rem !important; /* Smaller font size */
  }

  .fa-icon {
    font-size: 2rem !important; /* Smaller icon size */
  }
}

@media (max-width: 576px) {
  .equipment-product-layout {
    padding: 1% 1% !important;
  }

  .feature-item {
    width: 60% !important;
    margin: 0 20% 2rem 20% !important; /* 20% margin on each side */
    padding: 10px !important; /* Reduce padding */
  }

  .feature-item h4,
  .feature-item p {
    font-size: 0.875rem !important; /* Smaller font size */
  }

  .fa-icon {
    font-size: 2rem !important; /* Smaller icon size */
  }
}

/* New styles for icon features */
.feature-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 2rem;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-item h4 {
  color: #284053;
}

.feature-item p {
  color: #666;
}

.custom-icon-color {
  color: #284053;
}

.text-section h2 {
  color: #284053;
  margin-bottom: 1rem;
}

.text-section p {
  font-size: 1rem;
  color: #666;
}

/* Additional padding for the container */
.equipment-product-layout {
  padding: 5% 5%; /* Add 5% padding on all sides */
}
