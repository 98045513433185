/* Existing styles for Automatic Covers components */
@keyframes slideInFromTop {
  from { transform: translateY(-100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInFromBottom {
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.automatic-covers .cover-img-vacum {
  max-width: 100%;
  max-height: 350px;
  margin: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.automatic-covers .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-features{
  display: flex !important;
  justify-content: center !important;
}

.automatic-covers .text-section, .text-section-white {
  animation: slideInFromTop 0.5s ease-out forwards;
}

.automatic-covers .text-section-white h2 {
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.automatic-covers .text-section-white p {
  margin-bottom: 20px;
  color: white;
}

.automatic-covers .icon-features .col {
  text-align: center;
  padding: 10px;
  animation: slideInFromBottom 0.5s ease-out forwards;
}

.automatic-covers .cover-img-vacum:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.blue-background {
  background-color: #284053;
}

@media (max-width: 768px) {
  .automatic-covers .cover-img-vacum {
      width: 75px;
      height: 75px;
  }

  .automatic-covers .text-section, .automatic-covers .icon-features .col {
      text-align: center;
      padding-top: 2vh !important;
      padding-bottom: 2vh !important;
  }

  .text-animate {
    padding-top: 0.3vh !important;
  }
   

}

/* New styles for icon features */
.feature-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 2rem;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-item h4 {
  color: #284053;
}

.feature-item p {
  color: #666;
}

.custom-icon-color {
  color: #284053;
}

.text-section h2 {
  color: #284053;
  margin-bottom: 1rem;
}

.text-section p {
  font-size: 1rem;
  color: #666;
}

/* Additional padding for the container */
.automatic-covers {
  padding: 3% 3%; /* Add 5% padding on all sides */
}
