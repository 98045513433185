/* Contact.css */

/* ContactBanner.css */
.text-uppercase {
  text-transform: uppercase;
}

.text-white {
  color: #fff;
}

.text-animate {
  animation: textAnimation 1s ease-in-out forwards;
}

h1 {
  font-weight: bold;
  font-size: 2.5rem; /* Larger font size for large screens */
  text-align: center; /* Center align the title */
  padding-top: 5vh !important;
}
p,
a {
  text-decoration: none;
  color: gray;
}

.custom-hr {
  width: 50px; /* Fixed width for a clean look */
  height: 2px; /* Thickness of the horizontal rule */
  margin: auto; /* Center the horizontal rule horizontally */
  background-color: white;
}

.lead {
  font-size: 1.25rem; /* Larger text for large screens */
  text-align: center; /* Center align the text */
  padding: 0 15%; /* Padding on the sides for large screens */
}

/* Improved Button Style */
.btn-custom {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
}

.btn-custom:hover {
  background-color: white;
  color: #4caf50;
  border: 2px solid #4caf50;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-animate {
  animation: slideInFromLeft 1s ease-out forwards; /* Applying the animation with a duration of 1 second */
}

/* Responsive adjustments for medium devices (tablets) */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem; /* Slightly smaller font size for medium screens */
  }

  .lead {
    font-size: 1rem; /* Smaller font size for medium screens */
    padding: 0 10%; /* Less padding on the sides */
  }

  .container-fluid {
    padding: 3rem 0; /* Smaller padding for medium screens */
  }
}

/* Responsive adjustments for small devices (mobile phones) */
@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem; /* Much smaller font size for small screens */
  }

  .lead {
    font-size: 0.9rem; /* Smaller text size for better readability on small screens */
    padding: 0 5%; /* Minimal side padding */
  }

  .container-fluid {
    padding: 2rem 0; /* Minimal padding to save space */
  }
}

/* ContactForm.css */
.contact-form h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-form hr {
  border-top: 1px solid #000;
}

.contact-form .social-icon-contacts {
  margin-right: 10px;
  color: #000; /* Change as needed */
}

.contact-form .form-control {
  margin-bottom: 15px; /* Space between form fields */
}

.contact-form .col-md-8 {
  padding: 5vh;
}

.contact-form .service-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 1.5rem; /* Reduced margin */
}

.contact-form .service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-form .my-button-contact {
  background-color: white !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  color: #284053 !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
}

.contact-form .my-button-contact:hover {
  background-color: #284053 !important;
  border-color: #284053 !important;
  color: white !important;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .contact-form .col-md-4,
  .contact-form .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
