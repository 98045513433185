.footer {
    display: flex;
    justify-content: space-around;
    background-color: #284053;
    color: #fff;
    padding: 20px 0;
    font-family: Arial, sans-serif;
    flex-wrap: wrap;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    min-width: 200px; /* Adjust this value as needed */
}

.footer-column h1, .footer-column h3 {
    margin: 0;
    padding-bottom: 10px;
}

.footer-column p, .footer-column p a {
    margin: 5px 0;
    color: inherit;
    text-decoration: none;
}

.social-icon {
    color: #FFFFFF !important;
}

svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.footer-column > div {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.footer-column a:hover, .footer-column a:focus {
    text-decoration: underline;
    color: #e0e0e0;
    transition: color 0.3s ease;
}

.footer-column a:active {
    color: #a0a0a0;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: flex-start; /* Align items to the left */
        padding: 20px; /* Add some padding for better appearance */
    }

    .footer-column {
        align-items: flex-start; /* Align items to the left */
        text-align: left; /* Left align text */
        width: 100%; /* Make footer columns take full width */
        padding: 10px 0; /* Add some padding between items */
    }

    .footer-column > div {
        justify-content: flex-start; /* Align social icons to the left */
    }
}

/* Additional styling for desktop to ensure proper spacing */
.footer-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.footer-row:first-of-type .footer-column {
    max-width: 45%; /* Adjust to fit your design needs */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-row:first-of-type .footer-column .social-icon {
    margin-left: 10px;
}

.footer-row:last-of-type .footer-column {
    max-width: 30%;
}
