/* BeforeAfter.css */

.btn-custom {
  background-color: #284053 !important;
  border: 2px solid #284053 !important;
  border-radius: 20px !important;
  padding: 15px 50px !important;
  color: white !important;
  font-family: Arial, sans-serif;
  font-size: 22px !important;
  margin: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.btn-custom:hover {
  background-color: white !important;
  color: #284053 !important;
}

.btn-custom-selected {
  background-color: white !important;
  color: #284053 !important;
  border: 2px solid #284053 !important;
}

/* BeforeAfterSlider.css */
.before-after-slider {
  padding: 3rem 0; /* Add some padding around the container */
}

.compare-image-container {
  position: relative;
  width: 100%; /* Full width of the column */
  height: 400px; /* Fixed height */
  overflow: hidden; /* Hide overflow */
  background-color: white; /* White background for grid items */
  border-radius: 10px; /* Rounded corners for the grid items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 20px;
  margin-bottom: 1.5rem; /* Reduced margin */
}

.compare-image-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.compare-image-label {
  position: absolute;
  top: 20px; /* Distance from the top of the image */
  color: #fff; /* White color for visibility */
  font-size: 20px; /* Size of the text */
  z-index: 2; /* Make sure the label is above the image */
  padding: 5px 10px; /* Padding around the text */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s; /* Smooth transition for opacity and visibility */
}

.compare-image-label.before {
  left: 20px; /* Distance from the left side */
}

.compare-image-label.after {
  right: 20px; /* Distance from the right side */
}

.compare-image-container:hover .compare-image-label {
  visibility: visible; /* Make labels visible when hovering */
  opacity: 1; /* Fade in the labels */
}

@media (max-width: 768px) {
  .container-beforeAfter {
    padding-left: 5vh;
    padding-right: 5vh;
  }

  .col-md-4 {
    margin-bottom: 20px; /* Adds space between rows on smaller screens */
  }

  .compare-image-label {
    font-size: 16px; /* Smaller text on mobile devices */
  }
}

.compare-image-container .react-compare-image__slider-button {
  top: 50% !important; /* Center vertically */
  transform: translateY(-50%); /* Center alignment fix */
}

.custom-handle {
  width: 3px;
  height: 400px;
  background-color: #284053;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: ew-resize;
  z-index: 10;
}
