/* greenAreas.css */

/* Landscape Architecture Styles */

.landscape-architecture .text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures the column's content is vertically centered */
}

.landscape-architecture h2 {
  margin-bottom: 20px;
}

.landscape-architecture p {
  margin-bottom: 20px;
}

.landscape-architecture .btn {
  margin-top: auto; /* Pushes the button to the bottom of the column */
}

.landscape-architecture img {
  width: 70%; /* Ensures the image is responsive and covers the width of the column */
  height: auto; /* Keeps the image aspect ratio */
}

/* Improved Button Style */

/* Service Item Styles */
.greenAreas-service-item {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-left: 0.5vh !important;
  margin-right: 0.5vh !important;
}

.greenAreas-service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.greenAreas-service-item h4 {
  color: #284053;
}

.greenAreas-service-item p {
  color: #666;
}

.my-button-landscape {
  border: 10px solid 2px #fff !important;
  border-radius: 20px !important;
  padding: 10px 40px !important;
  font-family: Arial, sans-serif;
  font-size: 20px !important; /* Bootstrap primary color */
  background-color: white !important;
  border-color: #284053 !important;
  color: #284053 !important;
}

.my-button-landscape:hover {
  color: white !important;
  background-color: #284053 !important;
  border-color: white !important;
}

@media (max-width: 768px) {
  .landscape-architecture .row {
    flex-direction: column-reverse; /* Stacks the image on top on smaller screens */
  }

  .landscape-architecture .text-column {
    text-align: center; /* Centers the text on smaller screens */
  }
  .container-fluid {
    padding: 1vh !important;
  }
}

/* Service Options Styles */
/* Keyframes for animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animations to columns */
.col-md-4:nth-child(1) {
  /* Garden Projects */
  animation: slideInFromLeft 0.8s ease-out forwards;
}

.col-md-4:nth-child(2) {
  /* Garden Construction */
  animation: slideInFromBottom 0.8s ease-out forwards;
}

.col-md-4:nth-child(3) {
  /* Garden Maintenance */
  animation: slideInFromRight 0.8s ease-out forwards;
}

/* General Styles */
.container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.row {
  text-align: center;
}

.col-md-4 {
  margin-bottom: 2rem;
}

.col-md-4 h4 {
  margin-top: 1rem;
}

.col-md-4 p {
  font-size: 1rem;
  color: #666;
}

.fa-icon {
  color: #284053; /* Change color as needed */
}

@media (max-width: 768px) {
  .col-md-4 {
    margin-bottom: 1.5rem;
  }
}

/* Green Areas Banner Styles */

.text-uppercase {
  text-transform: uppercase;
}

.text-white {
  color: #fff;
}

.text-blue {
  color: #284053;
}

.text-animate {
  animation: textAnimation 1s ease-in-out forwards;
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.custom-hr {
  border-top: 2px solid #fff;
  width: 50%;
  margin: 1rem auto;
}

/* Circular images specific styles */
.rounded-circle {
  object-fit: cover;
  border-radius: 50%;
  display: block;
  margin: 10px auto;
  animation: slideUp 0.5s ease-out forwards;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rounded-circle:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}
